import axios from 'axios';
import {
    ElMessage
} from 'element-plus';

const service = axios.create({
    baseURL: '/api',
    timeout: 5000
})

//请求拦截
service.interceptors.request.use(config => {
    //登录接口不需要Token
    if (config.url != 'admin/admin/getToken') {
        config.headers = {
            'token': JSON.parse(localStorage.getItem('userInfo')).token,
            'version': "1.0.0",
            'api': '1'
        }
    }
    return config;
}, );

//响应拦截
service.interceptors.response.use(
    response => {
        if (response.data.code === 0) {
            return Promise.resolve(response.data);
        } else if (response.data.code === 1) {
            ElMessage({
                showClose: true,
                message: response.data.msg,
                type: 'error'
            })
        } else if (response.data.code === 2) {
            ElMessage({
                showClose: true,
                message: response.data.msg,
                type: 'error'
            });
            window.location.href = '/Login'
            localStorage.removeItem("userInfo")
        } else {
            ElMessage({
                showClose: true,
                message: response.data.msg,
                type: 'error'
            });
        }
    },
    error => {
        console.log(error);
        return Promise.reject();

    }
)


export default service