import {
    createApp
} from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import axios from '@/utils/request'
import router from './router'
// import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'

// createApp(App).mount('#app')

const app = createApp(App)
app.config.globalProperties.$axios = axios


app.use(ElementPlus, {
    locale
})
app.use(router)
app.mount('#app')