<template>
  <div class="header">
    <div class="header_title">
      <div class="header_lef">
        <div class="header_btn"></div>
        云平台
        <label style="font-size: 15px; margin-left: 10px">渠道管理系统</label>
      </div>
      <div class="header_rig">
        <el-dropdown>
          <el-button type="text" style="font-size: 20px"
            >{{ name
            }}<i class="el-icon-caret-bottom" style="font-size: 16px"></i
          ></el-button>
          <template #dropdown>
            <el-dropdown-menu style="text-align: center">
              <el-dropdown-item @click="editPlatInfo"
                >修改资料</el-dropdown-item
              >
              <el-dropdown-item @click="logout()">注销</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: JSON.parse(localStorage.getItem("userInfo")).name,
    };
  },
  methods: {
    editPlatInfo() {
      this.$router.push("/editPlatInfo");
    },
    // 注销
    logout() {
      this.$axios.get("admin/user/logout").then(() => {
        localStorage.removeItem("userInfo");
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 70px;
  background-color: #242f42;
  .header_title {
    padding: 0 30px;
    height: 100%;
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    .header_lef {
      justify-content: flex-start;
      align-items: center;
      font-size: 24px;
    }
    .header_rig {
      justify-content: flex-end;
      font-size: 20px;
    }
  }
}
</style>