<template>
  <div class="home">
    <Header />
    <div class="content">
      <Silder />
      <div class="content_box">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import Silder from "../../components/sidebar.vue";

export default {
  components: {
    Header,
    Silder,
  },
};
</script>

<style lang="scss" scoped>
body{
  overflow:hidden;
}
.home {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  // overflow-x: hidden;
  .content {
    display: flex;
    justify-content: space-around;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    .content_box {
      margin: 5px;
      flex: 1;
      overflow: auto;
    }
  }
}
</style>