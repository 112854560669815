<template>
  <!-- 侧边菜单栏 -->
  <div class="silder">
    <el-menu
      background-color="#324157"
      text-color="#fff"
      style="height: 100%"
      router
      unique-opened
      :default-active="defaultActive()"
    >
      <el-submenu index="1">
        <template #title>
          <i class="el-icon-s-platform"></i>
          <span>平台管理</span>
        </template>
        <el-menu-item>
          <el-menu-item index="ManageAccount">账号管理</el-menu-item>
        </el-menu-item>
      </el-submenu>
      <el-submenu index="2">
        <template #title>
          <i class="el-icon-s-tools"></i>
          <span>系统配置</span>
        </template>
        <el-menu-item>
          <el-menu-item index="ossSet">OSS配置</el-menu-item>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  methods: {
    goManageAccount() {
      this.$router.push("/ManageAccount");
    },
    goOssSet() {
      this.$router.push("/ossSet");
    },
    defaultActive() {
      return this.$route.name;
    },
  },
};
</script>

<style land="sass" scoped>
.silder {
  width: 200px;
  height: 100%;
}

.el-submenu .el-menu-item {
  min-width: 0 !important;
  padding: 0px !important;
  text-align: center;
}
</style>
