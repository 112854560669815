import {
    createRouter,
    createWebHistory
} from 'vue-router'
import Home from "../views/home/home.vue"

const routes = [{
        path: '/',
        redirect: '/ManageAccount'
    },
    {
        path: '/login',
        meta: {
            title: '登录'
        },
        component: () => import("../views/login/login.vue")
    },
    {
        path: '/',
        component: Home,
        children: [{
            path: '/ManageAccount',
            name: 'ManageAccount',
            meta: {
                title: "账号管理"
            },
            component: () => import("../views/Pluponform/ManageAccount")
        }, {
            path: '/editPlatInfo',
            meta: {
                title: "修改资料"
            },
            component: () => import("../views/Pluponform/editPlatInfo")
        }, {
            path: '/ossSet',
            name: 'ossSet',
            meta: {
                title: "OSS配置"
            },
            component: () => import("../views/systemManage/ossSet")
        }, ]
    }
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// 判断是否有登录信息
router.beforeEach((to, form, next) => {
    document.title = to.meta.title;
    const Info = localStorage.getItem("userInfo");
    if (!Info && to.path !== '/login') {
        next('/login');
    } else {
        next();
    }
})

export default router